import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401')),
        mobile: lazy(() => import('./pages/mobile/errors/Error401'))
      },
      {
        path: 'my-tickets',
        component: lazy(() => import('./pages/desktop/my-tickets/MyTickets')),
        mobile: lazy(() => import('./pages/mobile/my-tickets/MyTickets')),
        authKeys: ['Ticketing']
      },
      {
        path: 'users-list',
        component: lazy(() => import('./pages/desktop/users-list/UsersList')),
        authKeys: ['ChargeSection']
      },
      {
        path: 'ticket-details',
        component: lazy(() =>
          import('./pages/desktop/my-tickets/TicketDetails')
        ),
        mobile: lazy(() => import('./pages/mobile/my-tickets/TicketDetails')),
        authKeys: ['Ticketing']
      },
      {
        path: 'exchange-rates',
        component: lazy(() =>
          import('./pages/desktop/exchange-rates/ExchangeRates')
        ),
        authKeys: ['ChargeSection']
      },
      {
        path: 'user-migrations-list',
        component: lazy(() =>
          import('./pages/desktop/user-migration/UserMigrationsList')
        ),
        authKeys: ['Migration']
      },
      {
        path: 'sale-report',
        component: lazy(() =>
          import('./pages/desktop/sale-report/SaleReport')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'transaction-status',
        component: lazy(() =>
          import('./pages/desktop/transaction-status/TransactionStatus')
        ),
        authKeys: ['ChargeSection']
      },
      {
        path: 'services-management',
        component: lazy(() =>
          import('./pages/desktop/services-management/ServicesManagement')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'daily-health-sale',
        component: lazy(() =>
          import('./pages/desktop/daily-health-sale/DailyHealthSale')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'gardeshgari',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequest')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'gardeshgari-detail',
        component: lazy(() =>
          import('./pages/desktop/gardeshgari/GardeshgariBonCardRequestDetail')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'buy-charge-packages',
        component: lazy(() =>
          import('./pages/desktop/services-management/BuyChargePackages')
        ),
        authKeys: ['ChargeSection']

      },
      {
        path: 'submit-user-migration',
        component: lazy(() =>
          import('./pages/desktop/user-migration/SubmitUserMigration')
        ),
        authKeys: ['Migration']
      }
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404')),
    mobile: lazy(() => import('./pages/mobile/errors/Error404'))
  }
];

export default routes;
