import { ReactComponent as MyAriyanaLogo } from 'assets/brand/blueAriyanaBrand.svg';
import * as MyApiAuthorizationConstants from 'framework/base/authorization/ApiAuthorizationConstants';
import MyAuthRouteGuard from 'framework/base/authorization/AuthRouteGuard';
import MyAuthorizationProvider, {
  useAuth as myUseAuth
} from 'framework/base/authorization/AuthorizationProvider';
import myAuthService from 'framework/base/authorization/AuthorizeService';
import MyLoginCallbackHandler from 'framework/base/authorization/LoginCallbackHandler';
import MyNoHashQueryStringUtils from 'framework/base/authorization/noHashQueryStringUtils';

/* ****************** Instances ********************** */
export const AriyanaLogo = MyAriyanaLogo;

/* ****************** Authorization Instances ********************** */
export const ApiAuthorizationConstants = MyApiAuthorizationConstants;
export const AuthorizationProvider = MyAuthorizationProvider;
export const useAuth = myUseAuth;
export const authService = myAuthService;
export const AuthRouteGuard = MyAuthRouteGuard;
export const LoginCallbackHandler = MyLoginCallbackHandler;
export const NoHashQueryStringUtils = MyNoHashQueryStringUtils;

/* ****************** Authorization spec ********************** */
export const getPostLoginCallbackRoute = () =>
  sessionStorage.getItem('originalPath') || '/';

/* ****************** theme spec ********************** */
export const componentSizesExtraVars = {
  common: {},
  desktop: {},
  laptop: {},
  tablet: {},
  mobile: {}
};

/* ****************** styles spec ********************** */
export const sidebarDrawerPaperStyles = (MuiStyled, MuiDrawer) =>
  MuiStyled(MuiDrawer)`
    flex-basis: ${(props) =>
      props.open
        ? props.theme.componentSizes.sidebar.opened.width
        : props.theme.componentSizes.sidebar.closed.width};
    .MuiDrawer-paper {
      width: ${(props) =>
        props.open
          ? props.theme.componentSizes.sidebar.opened.width
          : props.theme.componentSizes.sidebar.closed.width};
      border: 0;
    }

    & .MuiDrawer-paper {
      transition: ${(props) =>
        props.theme.transitions.create('width', {
          easing: props.theme.transitions.easing.sharp,
          duration: props.open
            ? props.theme.transitions.duration.leavingScreen
            : props.theme.transitions.duration.enteringScreen
        })};
    }
  `;
export const sidebarTheme = 'blue';
export const idSidebarClosable = false;

/* ****************** environment spec ********************** */
export const imageAddressPrefix = '/';
export const openNewWindow = window.open;
export const getQueryStringsFromLocation = () =>
  window.location.search.substring(1);
export const showSettingsInNavbar = false;
export const showSwitchOptionInNavbar = false;
export const showCreditInNavbar = false;
export const getWindowLocationPathname = () => window.location.pathname;
